import React from "react";
import { Link } from "gatsby";
import { formatTagUrl } from "../utils/format";
import { Tag } from "./Tag";

export const BlogEntry: React.FC<{ edge: any }> = ({ edge }) => {
  const tags = edge.node.frontmatter?.tags;

  return (
    <div key={edge.node.id}>
      <h4 style={{ marginBottom: "8px" }}>
        <Link
          style={{ fontWeight: "normal" }}
          to={`/blog/${edge.node.slug}` || ""}
        >
          {edge.node.frontmatter?.title}
        </Link>
      </h4>
      <div style={{ marginBottom: "4px" }}>{edge.node.excerpt}</div>
      <div>
        {tags &&
          tags.map((tag: string) => (
            <Tag as={Link} key={tag} to={formatTagUrl(tag)}>
              {tag}
            </Tag>
          ))}
      </div>
    </div>
  );
};
