import * as React from "react";
import { Page } from "../components/Page";
import { LandingImage } from "../components/LandingImage/LandingImage";
import { graphql, PageProps } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Container } from "../components/Container";
import { BlogEntry } from "../components/BlogEntry";
import SEO from "../components/Seo";

// markup
const IndexPage = ({ data }: PageProps<Queries.IndexBlogListQuery>) => {
  const profileImage = data.file?.childImageSharp?.gatsbyImageData
    ? getImage(data.file.childImageSharp.gatsbyImageData)
    : null;
  return (
    <Page>
      <SEO title={"Home"} />
      <LandingImage />
      <Container
        display="flex"
        flexDirection="column"
        alignItems="center"
        paddingBottom={24}
      >
        <h1>Hey, nice to meet you!</h1>
        {profileImage && (
          <GatsbyImage
            alt={"Profile Image of Daniel Schulz"}
            image={profileImage}
            style={{ borderRadius: "50%" }}
          />
        )}
        <p>My name is Daniel, and I'm a Freelance Frontend Developer.</p>
        <p>
          The last 7 years of my professional experience were focused on
          architecting and developing web applications mainly within ReactJS and
          NodeJS ecosystems, where I consulted different customers to provide
          expert knowledge for their dedicated problems and lead high skilled
          teams to overcome those problems.
        </p>
        <p>
          Since the sharing of knowledge and the improvement of existing
          processes is one of my passions, I also worked in developer relations
          and giving lectures about advanced web technologies as a lecturer at
          the university.
        </p>
        <h2 style={{ alignSelf: "flex-start" }}>latest blog posts</h2>
        <div style={{ alignSelf: "flex-start" }}>
          {data.allMdx.edges.map((edge) => (
            <BlogEntry key={edge.node.id} edge={edge} />
          ))}
        </div>
      </Container>
    </Page>
  );
};

export const pageQuery = graphql`
  query IndexBlogList {
    file(relativePath: { eq: "profile.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allMdx(
      limit: 3
      filter: { fileAbsolutePath: { regex: "content/blog/i" } }
    ) {
      edges {
        node {
          id
          slug
          frontmatter {
            title
            date
            path
            tags
          }

          timeToRead
          excerpt
        }
      }
    }
  }
`;

export default IndexPage;
